import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import ScrollAnimation from 'react-animate-on-scroll'
import { Row, Col } from 'reactstrap'

import { Title, SubTitle, TagSection, TagContainer, SectionName } from './tags'

import ArrowVeryLong from '../images/arrow-verylong.svg'

const ContactsSection = styled(TagSection)`
  overflow: hidden;
`

const ContactsContainer = styled(TagContainer)`
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 200px;
  @media (max-width: 767px) {
    /* padding-top: 50px; */
    padding-top: 0;
    padding-left: 15px;
    padding-bottom: 80px;
  }
  .solid-line {
    &.one-line,
    &.two-line,
    &.three-line {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
`

const ContactsColOne = styled(Col)`
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 80px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
  p {
    color: ${(props) => props.theme.textColorGray};
    strong,
    b {
      color: ${(props) => props.theme.textColorBlack};
    }
  }
`

const ContactsItemWrapper = styled.div`
  @media (min-width: 768px) {
    min-height: 150px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    min-height: 120px;
  }
`

const ContactsTitle = styled(Title)`
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 10px;
  }
`

const ContactsSubTitle = styled(SubTitle)`
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 19px;
  }
  a {
    color: ${(props) => props.theme.textColorBlack};
  }
`

const ContactsColTwo = styled(Col)`
  /* padding-top: 15px;   */
  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    margin-left: -6px;
  }
  p {
    strong, b {
      /* font-family: ${(props) => props.theme.fontMontserrat}; */
      font-size: 31px;
      font-weight: 700;
    }
  }
`

const ContactsButtonMap = styled.a`
  position: relative;
  display: block;
  width: 100%;
  background-color: ${(props) => props.theme.colorOrange};
  text-transform: uppercase;
  line-height: 0.6;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  @media (max-width: 767px) {
    width: 225px;
  }
  &:hover,
  &:focus,
  &:active {
    &:after {
      right: 20px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 70%;
    right: 25px;
    display: block;
    /* width: 110px; */
    width: 30%;
    height: 8px;
    background-image: url(${ArrowVeryLong});
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
    margin-top: -4px;
    transition: all 0.2s ease;
  }
`

const ContactsButtonMapParts = styled.span`
  font-weight: 700;
`

const ContactsButtonMapPartsFirst = styled(ContactsButtonMapParts)`
  font-size: 21px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 15px;
  }
`

const ContactsButtonMapPartsTwo = styled(ContactsButtonMapParts)`
  font-size: 13px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 9px;
  }
  @media (max-width: 767px) {
    font-size: 10px;
  }
`

const Contacts = (props) => {
  return (
    <ContactsSection id="contacts">
      <ContactsContainer>
        <div className="solid-line first-line" />
        <div className="solid-line one-line" />
        <div className="solid-line two-line" />
        <div className="solid-line three-line" />
        <div className="solid-line last-line" />
        <SectionName>{props.data.frontmatter.section_name}</SectionName>
        <Row>
          <ContactsColOne xs="12" md={{ size: 6, offset: 3 }}>
            <ContactsItemWrapper>
              <ContactsTitle>
                {props.data.frontmatter.location_title}
              </ContactsTitle>
              <ContactsSubTitle
                dangerouslySetInnerHTML={{
                  __html: props.data.frontmatter.location,
                }}
              />
            </ContactsItemWrapper>
            <ContactsItemWrapper>
              <ContactsTitle>
                {props.data.frontmatter.phones_title}
              </ContactsTitle>
              <ContactsSubTitle
                dangerouslySetInnerHTML={{
                  __html: props.data.frontmatter.phones,
                }}
              />
            </ContactsItemWrapper>
            <ContactsItemWrapper>
              <ContactsTitle>
                {props.data.frontmatter.extra_link_title}
              </ContactsTitle>
              <ContactsSubTitle
                dangerouslySetInnerHTML={{
                  __html: props.data.frontmatter.extra_link,
                }}
              />
            </ContactsItemWrapper>
          </ContactsColOne>
          <ContactsColTwo xs="12" md="3">
            <ContactsItemWrapper>
              <ContactsTitle>{props.data.frontmatter.map_title}</ContactsTitle>

              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <ContactsSubTitle>
                  <ContactsButtonMap
                    href={props.data.frontmatter.map_link}
                    target="_blank"
                  >
                    <ContactsButtonMapPartsFirst>
                      <FormattedMessage id="BUTTON_MAP_PART_ONE" />
                    </ContactsButtonMapPartsFirst>
                    <br />
                    <ContactsButtonMapPartsTwo>
                      <FormattedMessage id="BUTTON_MAP_PART_TWO" />
                    </ContactsButtonMapPartsTwo>
                  </ContactsButtonMap>
                </ContactsSubTitle>
              </ScrollAnimation>
            </ContactsItemWrapper>
            <ContactsItemWrapper>
              <ContactsTitle>
                {props.data.frontmatter.email_title}
              </ContactsTitle>
              <ContactsSubTitle
                dangerouslySetInnerHTML={{
                  __html: props.data.frontmatter.email,
                }}
              />
            </ContactsItemWrapper>
          </ContactsColTwo>
        </Row>
      </ContactsContainer>
    </ContactsSection>
  )
}

export default Contacts
