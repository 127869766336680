import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const SmallLogoLink = styled(Link)`
  display: block;
`

const SmallLogoImg = styled(Img)`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`

const SmallLogoMobileImg = styled(Img)`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

const SmallLogo = (props) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo_small_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderMobileImage: file(
        relativePath: { eq: "logo_small_mobile.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <SmallLogoLink to={'/' + (props.locale === 'pl' ? '' : props.locale)}>
      <SmallLogoImg fluid={data.placeholderImage.childImageSharp.fluid} />
      <SmallLogoMobileImg
        fluid={data.placeholderMobileImage.childImageSharp.fluid}
      />
    </SmallLogoLink>
  )
}

export default SmallLogo
