import styled from 'styled-components'
import { Container } from 'reactstrap'

export const TagSection = styled.section`
  @media (max-width: 575px) {
    padding-left: 35px;
  }
`

export const TagContainer = styled(Container)`
  position: relative;
  background-color: ${(props) => props.theme.colorGrayLight};
  padding-top: 85px;
  padding-left: 80px;
  padding-right: 80px;
  z-index: 20;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 0;
    display: block;
    width: 2000px;
    background-color: ${(props) => props.theme.colorGrayLight};
    z-index: 10;
  }
  .solid-line {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    width: 2px;
    background-color: #cfcbc8;
    opacity: 0.4;
    z-index: 100;
    @media (max-width: 767px) {
      width: 1px;
    }
    &.first-line {
      left: 0;
    }
    &.one-line {
      left: 25%;
    }
    &.two-line {
      left: 50%;
    }
    &.three-line {
      left: 75%;
    }
    &.last-line {
      right: 0;
    }
  }
`

export const Title = styled.h2`
  color: ${(props) => props.theme.textColorGray};
  font-family: ${(props) => props.theme.fontMontserrat};
  font-size: 12px;
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 13px;
  }
`

export const SubTitle = styled.p`
  color: ${(props) => props.theme.textColorBlack}!important;
  font-family: ${(props) => props.theme.fontMontserrat};
  font-size: 31px;
  font-weight: 700;
  line-height: 1.227;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`

export const SectionName = styled.div`
  position: absolute;
  top: 350px;
  left: -340px;
  bottom: 0;
  width: 600px;
  height: 80px;
  opacity: 0.4;
  color: #bfb7b0;
  font-size: 45px;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2px;
  transform: rotate(-90deg);
  @media (min-width: 576px) and (max-width: 767px) {
    height: 30px;
    font-size: 30px;
  }
  @media (max-width: 575px) {
    left: -322px;
    height: 20px;
    font-size: 16px;
    letter-spacing: 1px;
  }
`
