import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

import OrphanRemove from './lib/orphan-remove'

import { Title, SubTitle, TagSection, TagContainer, SectionName } from './tags'

import SpecializationLineBG from '../images/specialization_line_bg.svg'

const SpecializationSection = styled(TagSection)`
  overflow: hidden;
`

const SpecializationContainer = styled(TagContainer)`
  position: relative;
  background-color: ${(props) => props.theme.colorGrayLight};
  padding-top: 85px;
  padding-left: 0;
  padding-right: 0;
  z-index: 20;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 640px;
    height: 50px;
    background-image: url(${SpecializationLineBG});
    background-repeat: no-repeat;
    background-size: contain;
    @media (min-width: 1600px) {
      width: 643px;
      height: 55px;
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      width: 572px;
      height: 44px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 482px;
      height: 40px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 360px;
      height: 35px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 405px;
    }
    @media (max-width: 575px) {
      width: 75%;
    }
  }
`

const SpecializationSectionName = styled(SectionName)`
  @media (max-width: 575px) {
    top: 405px;
  }
`

const SpecializationColOne = styled(Col)`
  /* padding-right: 400px; */
  padding-left: 95px;
  padding-right: 35%;
  margin-bottom: 80px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-right: 21%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-right: 15%;
  }
  @media (max-width: 767px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  p {
    color: ${(props) => props.theme.textColorGray};
    strong,
    b {
      color: ${(props) => props.theme.textColorBlack};
    }
  }
`

const SpecializationTitle = styled(Title)``

const SpecializationSubTitle = styled(SubTitle)``

const SpecializationColTwo = styled(Col)`
  padding-top: 15px;
  padding-left: 0;
  padding-right: 95px;
  border-top: 5px solid ${(props) => props.theme.colorOrange};
  @media (max-width: 767px) {
    padding-right: 30px;
  }
  p {
    strong, b {
      /* font-family: ${(props) => props.theme.fontMontserrat}; */
      font-size: 31px;
      font-weight: 700;
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
  }
`

const Specialization = (props) => {
  return (
    <SpecializationSection id="specialization">
      <SpecializationContainer>
        <div className="solid-line first-line" />
        <div className="solid-line one-line" />
        <div className="solid-line two-line" />
        <div className="solid-line three-line" />
        <div className="solid-line last-line" />
        <SpecializationSectionName>
          {props.data.frontmatter.section_name}
        </SpecializationSectionName>
        <Row>
          <SpecializationColOne xs="12">
            <SpecializationTitle>
              {props.data.frontmatter.title}
            </SpecializationTitle>

            <ScrollAnimation animateIn="bounceInLeft" animateOnce={true}>
              <SpecializationSubTitle>
                {props.locale === 'pl' ? (
                  <OrphanRemove text={props.data.frontmatter.sub_title} />
                ) : (
                  props.data.frontmatter.sub_title
                )}
              </SpecializationSubTitle>
            </ScrollAnimation>

            {props.locale === 'pl' ? (
              <OrphanRemove tagName="div" text={props.data.html} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: props.data.html }} />
            )}
          </SpecializationColOne>
          <Col xs="3" md="6" />
          <SpecializationColTwo xs="9" md="6">
            <ScrollAnimation animateIn="pulse" animateOnce={true}>
              {props.locale === 'pl' ? (
                <OrphanRemove
                  tagName="div"
                  text={props.data.frontmatter.sub_desc}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.data.frontmatter.sub_desc,
                  }}
                />
              )}
            </ScrollAnimation>
          </SpecializationColTwo>
        </Row>
      </SpecializationContainer>
    </SpecializationSection>
  )
}

export default Specialization
