import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import AliceCarousel from 'react-alice-carousel'

class ResponsibilityCarousel extends Component {
  componentDidMount() {
    setTimeout(() => {
      try {
        this.setHeight()
      } catch {}
    }, 1000)
  }

  setHeight() {
    if (typeof document !== 'undefined') {
      const node = ReactDOM.findDOMNode(this)
      if (node instanceof HTMLElement) {
        const carouselWrapper = node.querySelector('.alice-carousel__wrapper')

        carouselWrapper.style.height = ''

        const slideElem = node.querySelector(
          'li.alice-carousel__stage-item.__active'
        )
        const heightSlideElem = slideElem.offsetHeight

        carouselWrapper.style.height = heightSlideElem + 'px'
      }
    }
  }

  render() {
    const responsive = {
      0: { items: 1 },
    }

    return (
      <AliceCarousel
        items={this.props.items}
        responsive={responsive}
        autoPlay={true}
        autoPlayInterval={5000}
        dotsDisabled={true}
        onSlideChanged={() => this.setHeight()}
        onResized={() => this.setHeight()}
      />
    )
  }
}

export default ResponsibilityCarousel
