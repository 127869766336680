import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const HeaderBigLogoWrapper = styled.div`
  position: relative;
  top: -100px;
  left: -85px;
  z-index: 150;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50%;
    top: -25px;
    left: initial;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 80%;
    top: -20px;
    left: -30px;
  }
  @media (max-width: 767px) {
    top: -25px;
    left: inherit;
  }
`

const HeaderBigLogoImg = styled(Img)`
  width: 100%;
  /* height: 100%; */
`

const HeaderBigLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo_big_color.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <HeaderBigLogoWrapper>
      <HeaderBigLogoImg fluid={data.placeholderImage.childImageSharp.fluid} />
    </HeaderBigLogoWrapper>
  )
}

export default HeaderBigLogo
