import React, { Component } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Obfuscate from 'react-obfuscate'
import { FormattedMessage } from 'react-intl'
import ScrollAnimation from 'react-animate-on-scroll'
import { Link as LinkScroll } from 'react-scroll'
import { Scrollbar } from 'react-scrollbars-custom'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Button,
} from 'reactstrap'
import classnames from 'classnames'

import OrphanRemove from './lib/orphan-remove'

import { Title, SubTitle, TagSection, TagContainer, SectionName } from './tags'

import OverlayBG from '../images/team-overlay-bg.svg'
import ArrowShort from '../images/arrow-short.svg'

const TeamSection = styled(TagSection)`
  overflow: hidden;
`

const TeamHeaderCol = styled(Col)`
  position: relative;
  padding-top: 80px;
  padding-left: 95px;
  /* padding-right: 860px; */
  padding-right: 50%;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-right: 45%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-right: 35%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-right: 15%;
  }
  @media (max-width: 767px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const TeamTitle = styled(Title)``

const TeamSubTitle = styled(SubTitle)``

const TeamContainer = styled(TagContainer)`
  background-color: ${(props) => props.theme.colorGrayLight};
  padding-top: 85px;
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 767px) {
    padding-top: 0;
  }
  ${SectionName} {
    padding-right: 20%;
  }
`

const TeamColPerson = styled(Col)``

const TeamColPersonLeft = styled(TeamColPerson)`
  padding-right: 0;
  @media (max-width: 575px) {
    ${(props) => props.isvisible === 'false' && 'padding-right: 6px;'}
  }
`

const TeamColPersonRowWrapper = styled(Row)`
  height: 100%;
`

const TeamColPersonImage = styled(Col)`
  position: relative;
  padding-right: 0;
  z-index: 150;
  @media (max-width: 767px) {
    padding-top: 50px;
    padding-right: 15px;
  }
`

const TeamColPersonRight = styled(TeamColPerson)`
  display: ${(props) => (props.isvisible === 'true' ? 'block' : 'none')};
  padding-left: 0;
  ${TeamColPersonRowWrapper} {
    @media (min-width: 768px) {
      margin-left: 0;
    }
    ${TeamColPersonImage} {
      @media (min-width: 768px) {
        padding-left: 0;
      }
    }
  }
`

const TeamOtserTitle = styled.p`
  position: relative;
  top: 8px;
  color: ${(props) => props.theme.textColorWhite};
  font-family: ${(props) => props.theme.fontPlayfairDisplay};
  font-size: 34px;
  font-weight: 400;
  font-style: italic;
  padding-left: 25px;
  margin-top: 90px;
  margin-bottom: 0;
  @media (max-width: 767px) {
    margin-top: 25px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    /* right: 95px; */
    right: 26%;
    bottom: 0;
    display: block;
    height: 6px;
    background-color: #270d26;
    @media (min-width: 576px) and (max-width: 767px) {
      right: 10.9%;
    }
    @media (max-width: 575px) {
      right: 8%;
      height: 3px;
    }
  }
`

const TeamNavWrapper = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

const TeamNav = styled(Nav)``

const TeamNavColWrapperMobile = styled(Col)`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

const TeamNavItem = styled(NavItem)`
  display: block;
  width: 100%;
`

const TeamNavLink = styled(NavLink)`
  /* position: relative; */
  font-family: ${(props) => props.theme.fontMontserrat};
  font-size: 22px;
  font-weight: 700;
  padding-top: 30px;
  /* padding-left: 95px;
  padding-right: 95px; */
  padding-left: 26%;
  padding-right: 26%;
  padding-bottom: 30px;
  border: none !important;
  cursor: pointer;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
    padding-left: 45%;
    padding-right: 0;
  }
  @media (max-width: 575px) {
    font-size: 15px;
    padding-left: 45%;
    padding-right: 0;
    padding-bottom: 0;
  }
  &.active {
    background-color: transparent !important;
  }
`

const TeamNavElementScroll = styled(LinkScroll)`
  color: ${(props) => props.theme.textColorBlack};
  &:hover,
  &:focus {
    color: ${(props) => props.theme.textColorBlack};
  }
`

const TeamColPersonDesc = styled(Col)`
  position: relative;
  padding-top: 128px;
  padding-left: 0;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-top: 114px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 96px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 72px;
  }
  @media (max-width: 767px) {
    padding-top: 0;
    padding-left: 15px;
  }
`

const TeamColPersonDescButtonClose = styled(Button)`
  position: absolute;
  top: -12px;
  right: 15px;
  font-size: 40px;
  @media (max-width: 767px) {
    display: none;
  }
  &:focus {
    outline: none;
  }
`

const TeamColPersonDescButtonCloseMobile = styled(TeamColPersonDescButtonClose)`
  display: none;
  top: initial;
  right: 20px;
  bottom: -80px;
  z-index: 100;
  @media (max-width: 767px) {
    display: inline-block;
  }
`

const TeamPersonDesc = styled.div`
  position: relative;
  background-color: #270d26;
  color: ${(props) => props.theme.textColorWhite};
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  border-bottom: 8px solid ${(props) => props.theme.colorOrange};
  z-index: 150;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 15px;
  }
  @media (max-width: 767px) {
    padding-left: 0;
    border-bottom: none;
  }
`

const TeamPersonDescButton = styled(Button)`
  position: relative;
  display: block;
  min-width: 140px;
  height: 34px;
  background-color: ${(props) => props.theme.colorOrange};
  color: ${(props) => props.theme.textColorBlack};
  font-size: 15px;
  font-weight: 700;
  line-height: 1.8;
  text-transform: uppercase;
  padding-right: 60px;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    display: none;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.colorOrange}!important;
    color: ${(props) => props.theme.textColorBlack}!important;
    border: none;
    box-shadow: none !important;
    outline: none;
    &:after {
      right: 5px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    display: block;
    width: 50px;
    height: 8px;
    background-image: url(${ArrowShort});
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
    margin-top: -4px;
    transition: all 0.2s ease;
  }
`

const TeamPersonDescLeft = styled(TeamPersonDesc)`
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    bottom: -8px;
    display: block;
    width: 2000px;
    background-color: #270d26;
    border-bottom: 8px solid ${(props) => props.theme.colorOrange};
    z-index: 50;
    @media (max-width: 767px) {
      content: none;
    }
  }
`

const TeamPersonDescTitle = styled.h3`
  font-family: ${(props) => props.theme.fontMontserrat};
  font-size: 31px;
  font-weight: 700;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 17px;
  }
  @media (max-width: 575px) {
    font-size: 15px;
  }
`

const TeamPersonDescRank = styled.p`
  color: #ffe5b4;
  font-family: ${(props) => props.theme.fontMontserrat};
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 9px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
  }
`

const TeamPersonDescEmailWrapper = styled(TeamPersonDescRank)`
  color: #ffe5b4;
  font-family: ${(props) => props.theme.fontMontserrat};
  font-size: 12px;
  font-weight: 700;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 9px;
  }
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 12px;
  }
  @media (max-width: 575px) {
    font-size: 11px;
  }
  @media (max-width: 575px) {
    font-size: 9px;
  }
`

const TeamPersonDescEmail = styled(Obfuscate)`
  color: ${(props) => props.theme.textColorWhite};
  &:hover,
  &:focus {
    color: ${(props) => props.theme.textColorWhite};
  }
`

const TeamTextDesc = styled.div`
  position: relative;
  display: block;
  height: 100%;
  @media (max-width: 767px) {
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
  }
  @media (max-width: 575px) {
    font-size: 13px;
  }
  @media (max-width: 375px) {
    font-size: 12px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 10px;
    bottom: 175px;
    display: block;
    height: 220px;
    background-image: url(${OverlayBG});
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 992px) and (max-width: 1199px) {
      bottom: 150px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      bottom: 140px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      bottom: 140px;
    }
  }
`

const TeamScrollbar = styled(Scrollbar)`
  height: 100%;
  .ScrollbarsCustom-Track {
    &.ScrollbarsCustom-TrackY {
      top: 0 !important;
      width: 9px !important;
      background-color: #7c737c;
      border-radius: 0 !important;
      z-index: 150;
      .ScrollbarsCustom-Thumb {
        &.ScrollbarsCustom-ThumbY {
          background-color: #270d26 !important;
          border-radius: 0 !important;
        }
      }
    }
    &.ScrollbarsCustom-TrackX {
      display: none;
    }
  }
`

const TeamTextDescWrapper = styled.div`
  height: 100%;
  padding-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 180px;
  @media (min-width: 1200px) {
    padding-bottom: 180px;
  }
  @media (max-width: 575px) {
    padding-top: 30px;
    padding-left: 8px;
    padding-right: 8px;
  }
`

class Team extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: 1,
      isActiveRight: true,
      isOpenDescLeft: false,
      isOpenDescRight: false,
      defaultWidthOneDesc: '6',
      scrollbarHeight: 1350,
      persons: this.props.persons.edges,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      let scrollbarHeight = this.setState.scrollbarHeight

      if (window.innerWidth <= 1199) {
        scrollbarHeight = 1100
      }
      if (window.innerWidth <= 991) {
        scrollbarHeight = 800
      }
      if (window.innerWidth <= 767) {
        scrollbarHeight = 700
      }
      if (window.innerWidth <= 575) {
        scrollbarHeight = 600
      }
      this.setState({
        scrollbarHeight: scrollbarHeight,
      })
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      let defaultWidthOneDesc = '6'

      if (typeof window !== 'undefined') {
        if (window.innerWidth <= 575) {
          defaultWidthOneDesc = '9'
        }
      }

      this.setState({
        activeTab: tab,
        isActiveRight: false,
        defaultWidthOneDesc: defaultWidthOneDesc,
      })
    }
  }

  setDefaultTabs() {
    this.setState({
      activeTab: 1,
      isActiveRight: true,
      defaultWidthOneDesc: '6',
    })
  }

  toggleOpenDesc(descSide) {
    if (descSide === 'left') {
      this.setState({
        isOpenDescLeft: !this.state.isOpenDescLeft,
      })
    }
    if (descSide === 'right') {
      this.setState({
        isOpenDescRight: !this.state.isOpenDescRight,
      })
    }
  }

  render() {
    return (
      <TeamSection id="team">
        <TeamContainer>
          <div className="solid-line first-line" />
          <div className="solid-line one-line" />
          <div className="solid-line two-line" />
          <div className="solid-line three-line" />
          <div className="solid-line last-line" />
          <SectionName>{this.props.data.frontmatter.section_name}</SectionName>
          <Row>
            <TeamHeaderCol xs="12">
              <TeamTitle>{this.props.data.frontmatter.title}</TeamTitle>
              <ScrollAnimation animateIn="bounceInLeft" animateOnce={true}>
                <TeamSubTitle>
                  {this.props.locale === 'pl' ? (
                    <OrphanRemove
                      text={this.props.data.frontmatter.sub_title}
                    />
                  ) : (
                    this.props.data.frontmatter.sub_title
                  )}
                </TeamSubTitle>
              </ScrollAnimation>

              {!this.state.isActiveRight && (
                <TeamColPersonDescButtonCloseMobile
                  onClick={() => this.setDefaultTabs()}
                  close
                />
              )}
            </TeamHeaderCol>

            <Col id="teamPersons">
              <Row>
                <TeamColPersonLeft
                  xs={this.state.defaultWidthOneDesc}
                  isvisible={this.state.isActiveRight.toString()}
                >
                  <TabContent activeTab={this.state.activeTab}>
                    {this.state.persons.map(({ node }) => {
                      return (
                        <TabPane key={node.id} tabId={node.frontmatter.order}>
                          <TeamColPersonRowWrapper>
                            <TeamColPersonImage xs="12" md="6">
                              <Img
                                fluid={
                                  node.frontmatter.image.childImageSharp.fluid
                                }
                              />
                              {this.state.activeTab ===
                                node.frontmatter.order && (
                                <TeamNavWrapper>
                                  <TeamOtserTitle>
                                    <span>
                                      {this.props.data.frontmatter.title_other}
                                    </span>
                                    :
                                  </TeamOtserTitle>
                                  <TeamNav tabs>
                                    {this.state.persons
                                      .slice(2)
                                      .map(({ node }, idx) => {
                                        return (
                                          <TeamNavItem key={node.id}>
                                            <ScrollAnimation
                                              key={`team-animate1-${idx}`}
                                              animateIn="fadeIn"
                                              animateOnce={true}
                                              delay={idx * 500}
                                              duration={1}
                                              offset={0}
                                            >
                                              <TeamNavLink
                                                as="div"
                                                className={classnames({
                                                  active:
                                                    this.state.activeTab ===
                                                    node.frontmatter.order,
                                                })}
                                              >
                                                <TeamNavElementScroll
                                                  // as="div"
                                                  href="#"
                                                  to="teamPersons"
                                                  offset={0}
                                                  smooth
                                                  onClick={() => {
                                                    this.toggle(
                                                      node.frontmatter.order
                                                    )
                                                  }}
                                                >
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        node.frontmatter.name,
                                                    }}
                                                  />
                                                </TeamNavElementScroll>
                                              </TeamNavLink>
                                            </ScrollAnimation>
                                          </TeamNavItem>
                                        )
                                      })}
                                  </TeamNav>
                                </TeamNavWrapper>
                              )}
                            </TeamColPersonImage>
                            <TeamColPersonDesc xs="12" md="6">
                              {!this.state.isActiveRight && (
                                <TeamColPersonDescButtonClose
                                  onClick={() => this.setDefaultTabs()}
                                  close
                                />
                              )}
                              <TeamPersonDescLeft>
                                <TeamPersonDescTitle
                                  dangerouslySetInnerHTML={{
                                    __html: node.frontmatter.name,
                                  }}
                                />
                                <TeamPersonDescRank>
                                  {node.frontmatter.status}
                                </TeamPersonDescRank>
                                <TeamPersonDescEmailWrapper>
                                  {/* {'e-mail: '} */}
                                  <TeamPersonDescEmail
                                    email={node.frontmatter.email}
                                  />
                                </TeamPersonDescEmailWrapper>
                                <TeamPersonDescButton
                                  onClick={() => this.toggleOpenDesc('right')}
                                >
                                  {!this.state.isOpenDescRight ? (
                                    <FormattedMessage id="BUTTON_MORE" />
                                  ) : (
                                    <FormattedMessage id="BUTTON_HIDE" />
                                  )}
                                </TeamPersonDescButton>
                              </TeamPersonDescLeft>
                              <TeamTextDesc isOpen={this.state.isOpenDescRight}>
                                <TeamScrollbar
                                  style={{ height: this.state.scrollbarHeight }}
                                >
                                  <TeamTextDescWrapper>
                                    {this.props.locale === 'pl' ? (
                                      <OrphanRemove
                                        tagName="div"
                                        text={node.html}
                                      />
                                    ) : (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: node.html,
                                        }}
                                      />
                                    )}
                                  </TeamTextDescWrapper>
                                </TeamScrollbar>
                              </TeamTextDesc>
                            </TeamColPersonDesc>
                          </TeamColPersonRowWrapper>
                        </TabPane>
                      )
                    })}
                  </TabContent>
                </TeamColPersonLeft>
                <TeamColPersonRight
                  xs="6"
                  isvisible={this.state.isActiveRight.toString()}
                >
                  <TeamColPersonRowWrapper>
                    <TeamColPersonImage xs="12" md="6">
                      <Img
                        fluid={
                          this.state.persons[1].node.frontmatter.image
                            .childImageSharp.fluid
                        }
                      />
                    </TeamColPersonImage>
                    <TeamColPersonDesc xs="12" md="6">
                      <TeamPersonDesc>
                        <TeamPersonDescTitle
                          dangerouslySetInnerHTML={{
                            __html: this.state.persons[1].node.frontmatter.name,
                          }}
                        />
                        <TeamPersonDescRank>
                          {this.state.persons[1].node.frontmatter.status}
                        </TeamPersonDescRank>
                        <TeamPersonDescEmailWrapper>
                          {/* {'e-mail: '} */}
                          <TeamPersonDescEmail
                            email={this.state.persons[1].node.frontmatter.email}
                          />
                        </TeamPersonDescEmailWrapper>
                        <TeamPersonDescButton
                          onClick={() => this.toggleOpenDesc('left')}
                        >
                          {!this.state.isOpenDescLeft ? (
                            <FormattedMessage id="BUTTON_MORE" />
                          ) : (
                            <FormattedMessage id="BUTTON_HIDE" />
                          )}
                        </TeamPersonDescButton>
                      </TeamPersonDesc>
                      <TeamTextDesc isOpen={this.state.isOpenDescLeft}>
                        <TeamScrollbar
                          style={{ height: this.state.scrollbarHeight }}
                        >
                          <TeamTextDescWrapper>
                            {this.props.locale === 'pl' ? (
                              <OrphanRemove
                                tagName="div"
                                text={this.state.persons[1].node.html}
                              />
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: this.state.persons[1].node.html,
                                }}
                              />
                            )}
                          </TeamTextDescWrapper>
                        </TeamScrollbar>
                      </TeamTextDesc>
                    </TeamColPersonDesc>
                  </TeamColPersonRowWrapper>
                </TeamColPersonRight>
                <TeamNavColWrapperMobile xs="7">
                  <TeamOtserTitle>
                    <span>{this.props.data.frontmatter.title_other}</span>:
                  </TeamOtserTitle>
                  <TeamNav tabs>
                    {this.state.persons.slice(2).map(({ node }, idx) => {
                      return (
                        <TeamNavItem key={node.id}>
                          <ScrollAnimation
                            key={`team-animate-${idx}`}
                            animateIn="fadeIn"
                            animateOnce={true}
                            delay={idx * 500}
                            duration={1}
                          >
                            <TeamNavLink
                              as="div"
                              className={classnames({
                                active:
                                  this.state.activeTab ===
                                  node.frontmatter.order,
                              })}
                            >
                              <TeamNavElementScroll
                                // as="div"
                                href="#"
                                to="teamPersons"
                                offset={50}
                                smooth
                                onClick={() => {
                                  this.toggle(node.frontmatter.order)
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: node.frontmatter.name,
                                  }}
                                />
                              </TeamNavElementScroll>
                            </TeamNavLink>
                          </ScrollAnimation>
                        </TeamNavItem>
                      )
                    })}
                  </TeamNav>
                </TeamNavColWrapperMobile>
              </Row>
            </Col>
          </Row>
        </TeamContainer>
      </TeamSection>
    )
  }
}

export default Team
