import React, { Component } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Container,
} from 'reactstrap'
import classnames from 'classnames'
// import ScrollAnimation from 'react-animate-on-scroll'

import OrphanRemove from './lib/orphan-remove'

import HeaderBigLogo from './header-big-logo'

import TabNavBg from '../images/tab-nav-bg.svg'
import TabNavMobileBG from '../images/tab-nav-mobile-bg.svg'

const AdvantagesWrapper = styled.div`
  position: relative;
  @media (max-width: 575px) {
    padding-left: 35px;
  }
`

const AdvantagesContainer = styled(Container)`
  position: relative;
  padding-top: 70px;
  @media (min-width: 1600px) {
    /* height: 840px; */
    height: calc(100vh - 84px);
  }
  @media (min-width: 1370px) and (max-width: 1599px) {
    /* height: 620px; */
    height: calc(100vh - 84px);
  }
  @media (min-width: 1200px) and (max-width: 1369px) {
    /* height: 580px; */
    height: calc(100vh - 84px);
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    height: auto;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 0;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 0;
  }
  @media (max-width: 767px) {
    height: auto;
    padding-top: 0;
  }
  .solid-line {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    width: 2px;
    background-color: #cfcbc8;
    opacity: 0.1;
    z-index: 100;
    @media (max-width: 767px) {
      width: 1px;
    }
    &.first-line {
      left: 0;
    }
    &.one-line {
      left: 25%;
    }
    &.two-line {
      left: 50%;
    }
    &.three-line {
      left: 75%;
    }
    &.last-line {
      right: 0;
    }
  }
`

const AdvantagesRow = styled(Row)`
  height: 100%;
`

const AdvantagesColBigLogo = styled(Col)`
  height: 100%;
  @media (min-width: 992px) and (max-width: 1199px) {
    display: flex;
    justify-content: center;
  }
`

const AdvantagesNavLinkBGWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colorBlueDark}!important;
  background-image: url(${TabNavBg});
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: cover;
  opacity: 0.4;
  z-index: 10;
  @media (max-width: 767px) {
    background-color: rgba(191, 183, 176, 0.502) !important;
    background-image: url(${TabNavMobileBG});
  }
`

const AdvantagesNavLinkTextWrapper = styled.div`
  position: relative;
  font-family: ${(props) => props.theme.fontRobotoCondensed};
  font-size: 17px;
  font-weight: 300;
  z-index: 20;
  @media (max-width: 575px) {
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 440px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`

const AdvantagesNavLink = styled(NavLink)`
  position: relative;
  background-color: transparent !important;
  /* color: #444248 !important; */
  color: #757477 !important;
  padding-top: 35px;
  padding-left: 50px;
  padding-bottom: 30px;
  border: none !important;
  border-bottom: 4px solid transparent !important;
  border-radius: 0 !important;
  cursor: pointer;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-top: 20px;
    padding-left: 10px;
    padding-bottom: 15px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 25px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colorWhite} !important;
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 18px;
    border-bottom: 7px solid transparent !important;
  }
  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 375px) {
    padding-top: 20px;
    padding-bottom: 15px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1px;
    display: block;
    width: 30px;
    height: 1px;
    background-color: #cfcbc8;
    opacity: 0.1;
    @media (min-width: 1200px) and (max-width: 1599px) {
      width: 15px;
    }
    @media (max-width: 767px) {
      content: '';
      position: absolute;
      top: initial;
      left: 0;
      right: 0;
      bottom: -7px;
      display: block;
      width: initial;
      height: 1px;
      background-color: ${(props) => props.theme.colorBlack};
      opacity: 1;
      z-index: 150;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: -6px;
    display: block;
    width: 1px;
    background-color: ${(props) => props.theme.colorBlack};
    z-index: 150;
    @media (min-width: 768px) {
      content: none;
    }
  }
  ${AdvantagesNavLinkTextWrapper} {
    @media (min-width: 1200px) and (max-width: 1599px) {
      font-size: 14px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 15px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
    @media (max-width: 375px) {
      font-size: 13px;
    }
    @media (max-width: 360px) {
      font-size: 11px;
    }
  }
  &.active {
    background-color: transparent !important;
    color: ${(props) => props.theme.textColorWhite}!important;
    padding-left: 15px;
    border-bottom: 4px solid ${(props) => props.theme.colorOrangeDark}!important;
    @media (max-width: 767px) {
      border-bottom: 7px solid
        ${(props) => props.theme.colorOrangeDark}!important;
    }
    @media (max-width: 575px) {
      padding-left: 0;
    }
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
    ${AdvantagesNavLinkBGWrapper} {
      display: block;
    }
    ${AdvantagesNavLinkTextWrapper} {
      font-size: 21px;
      @media (min-width: 1200px) and (max-width: 1599px) {
        font-size: 17px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 16px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
      @media (max-width: 375px) {
        font-size: 13px;
      }
      @media (max-width: 360px) {
        font-size: 11px;
      }
    }
  }
`

const AdvantagesNavItem = styled(NavItem)`
  width: 100%;
  @media (max-width: 767px) {
    position: relative;
    width: 100%;
    flex: 0 0 50%;
    max-width: 50%;
  }
`

const AdvantagesNav = styled(Nav)`
  position: relative;
  border-bottom: none;
  z-index: 20;
  ${AdvantagesNavItem} {
    &:nth-child(even) {
      ${AdvantagesNavLink} {
        &:after {
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }
  }
`

const AdvantagesColNavs = styled(Col)`
  height: 100%;
  padding-left: 0;
  padding-right: 0;
  @media (min-width: 768px) and (max-width: 1199px) {
    position: relative;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    min-height: 710px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    min-height: 585px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
  }
  &:before {
    @media (min-width: 768px) and (max-width: 1199px) {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      opacity: 0.4;
      display: block;
      height: 1px;
      background-color: #cfcbc8;
      z-index: 150;
    }
  }
`

const AdvantagesColTabContent = styled(Col)`
  height: 100%;
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const AdvantagesTabContent = styled(TabContent)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  width: 340%;
  color: #fff;
  z-index: 10;
  @media (min-width: 1460px) and (max-width: 1599px) {
    /* width: 180%; */
    width: ${(props) =>
      props.scalingFactor ? 195 * props.scalingFactor : 195}%;
  }
  @media (min-width: 1400px) and (max-width: 1459px) {
    width: ${(props) =>
      props.scalingFactor ? 175 * props.scalingFactor : 175}%;
  }
  @media (min-width: 1370px) and (max-width: 1399px) {
    /* width: 158%; */
    width: ${(props) =>
      props.scalingFactor ? 200 * props.scalingFactor : 200}%;
  }
  @media (min-width: 1281px) and (max-width: 1369px) {
    /* width: 147%; */
    width: ${(props) =>
      props.scalingFactor ? 158 * props.scalingFactor : 158}%;
  }
  @media (min-width: 1200px) and (max-width: 1280px) {
    /* width: 147%; */
    width: ${(props) =>
      props.scalingFactor ? 150 * props.scalingFactor : 150}%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 123%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 135.5%;
  }
  @media (max-width: 767px) {
    position: static;
    width: 100%;
  }
`

const AdvantagesTabPane = styled(TabPane)`
  width: 640px;
`

const AdvantagesTabPaneImg = styled(Img)`
  img {
    height: auto !important;

    @media (min-width: 1200px) and (max-width: 1399px) {
      left: -12% !important;
    }

    @media (min-width: 1400px) and (max-width: 1920px) {
      left: -7% !important;
    }
  }
`

class Advantages extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: 1,
      slides: this.props.slides.edges,
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  render() {
    return (
      <AdvantagesWrapper>
        <AdvantagesContainer>
          <div className="solid-line first-line" />
          <div className="solid-line one-line" />
          <div className="solid-line two-line" />
          <div className="solid-line three-line" />
          <div className="solid-line last-line" />
          <AdvantagesRow>
            <AdvantagesColBigLogo xs="12" xl="6">
              <HeaderBigLogo />
            </AdvantagesColBigLogo>
            <AdvantagesColNavs xs="12" md="6" xl="3">
              <AdvantagesNav tabs>
                {this.state.slides.map(({ node }, idx) => {
                  return (
                    <AdvantagesNavItem
                      key={node.id}
                      className={`animated fadeIn delay-${idx + 1}s`}
                    >
                      <AdvantagesNavLink
                        className={classnames({
                          active: this.state.activeTab === idx + 1,
                        })}
                        onClick={() => {
                          this.toggle(idx + 1)
                        }}
                      >
                        <AdvantagesNavLinkTextWrapper>
                          {this.props.locale === 'pl' ? (
                            <OrphanRemove
                              tagName="div"
                              text={node.frontmatter.title}
                            />
                          ) : (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: node.frontmatter.title,
                              }}
                            />
                          )}
                        </AdvantagesNavLinkTextWrapper>
                        <AdvantagesNavLinkBGWrapper />
                      </AdvantagesNavLink>
                    </AdvantagesNavItem>
                  )
                })}
              </AdvantagesNav>
            </AdvantagesColNavs>
            <AdvantagesColTabContent xs="12" md="6" xl="3">
              <AdvantagesTabContent
                activeTab={this.state.activeTab}
                scalingFactor={
                  this.state.slides[this.state.activeTab - 1].node.frontmatter
                    .scaling_factor
                }
              >
                {this.state.slides.map(({ node }, idx) => {
                  return (
                    <AdvantagesTabPane key={node.id} tabId={idx + 1}>
                      <Row>
                        <Col sm="12">
                          <AdvantagesTabPaneImg
                            fluid={node.frontmatter.image.childImageSharp.fluid}
                          />
                        </Col>
                      </Row>
                    </AdvantagesTabPane>
                  )
                })}
              </AdvantagesTabContent>
            </AdvantagesColTabContent>
          </AdvantagesRow>
        </AdvantagesContainer>
      </AdvantagesWrapper>
    )
  }
}

export default Advantages
