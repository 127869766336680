import React from 'react'
import PropTypes from 'prop-types'

class OrphanRemove extends React.Component {
  // based on:
  // https://github.com/RustBeard/Hanging-conjunctions/blob/master/hanging_conjunctions.php

  conjuctions = [
    '[a-z]',
    'aby',
    'ale',
    'ani',
    'aż',
    'bo',
    'by',
    'bym',
    'byś',
    'co',
    'czy',
    'dla',
    'do',
    'gdy',
    'gdyż',
    'iż',
    'jak',
    'je',
    'lub',
    'na',
    'nad',
    'niż',
    'od',
    'oraz',
    'po',
    'pod',
    'te',
    'tej',
    'to',
    'we',
    'za',
    'zaś',
    'ze',
    'że',
  ]

  replacements = [' $1&nbsp;-&nbsp;', ' $1&nbsp;', ' $1,&nbsp;']

  fixHangingConjuctions(text) {
    var pattern = [],
      patterns = []
    for (var i = 0; i < this.replacements.length; i++) {
      for (var j = 0; j < this.conjuctions.length; j++) {
        patterns = [
          new RegExp(`\\s(${this.conjuctions[j]})(\\s)-\\s`, 'gi'),
          new RegExp(`\\s(${this.conjuctions[j]})(\\s)`, 'gi'),
          new RegExp(`\\s(${this.conjuctions[j]}),(\\s)`, 'gi'),
        ]
        if (typeof pattern[i] === 'undefined') pattern[i] = []
        pattern[i].push(patterns[i])

        text = text.replace(pattern[i][j], this.replacements[i])
      }
    }
    return text
  }

  fixOrphans(text) {
    return text.replace(/(\w+)\s(.*)/g, '$1&nbsp;$2')
  }

  fixWidows(text) {
    if (text.split(' ').length <= 3) return text
    return text.replace(/\s(?=[^\s]*$)/g, '&nbsp;')
  }

  render() {
    let {
      tagName,
      text,
      fixHangingConjuctions,
      fixOrphans,
      fixWidows,
      ...props
    } = this.props
    const TagName = tagName || 'span'

    if (text) {
      if (fixHangingConjuctions) text = this.fixHangingConjuctions(text)
      if (fixWidows) text = this.fixWidows(text)
      if (fixOrphans) text = this.fixOrphans(text)
    }

    return <TagName dangerouslySetInnerHTML={{ __html: text }} {...props} />
  }
}

OrphanRemove.propTypes = {
  tagName: PropTypes.string,
  fixHangingConjuctions: PropTypes.bool,
  fixOrphans: PropTypes.bool,
  fixWidows: PropTypes.bool,
}

OrphanRemove.defaultProps = {
  fixHangingConjuctions: true,
  fixOrphans: true,
  fixWidows: true,
}

export default OrphanRemove
