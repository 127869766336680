import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Link as LinkScroll } from 'react-scroll'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import SmallLogo from './small-logo'

import Hamburger from '../images/hamburger.svg'

const NavbarMenuWrapper = styled(Navbar)`
  background-color: transparent !important;
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: 767px) {
    background-color: ${(props) => props.theme.colorWhite} !important;
    padding-top: 15px;
    /* padding-bottom: 15px; */
  }
`

const NavbarMenuContainer = styled(Container)`
  position: relative;
  padding-top: 35px;
  padding-bottom: 10px;
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    left: -20px;
    right: -80px;
    bottom: 0;
    display: block;
    height: 2px;
    background-color: ${(props) => props.theme.colorWhite};
  }
  .solid-line {
    position: absolute;
    top: 50%;
    bottom: 0;
    display: block;
    width: 2px;
    background-color: #cfcbc8;
    opacity: 0.05;
    z-index: 100;
    @media (max-width: 767px) {
      display: none;
      width: 1px;
    }
    &.first-line {
      left: 0;
    }
    &.one-line {
      left: 25%;
    }
    &.two-line {
      left: 50%;
    }
    &.three-line {
      left: 75%;
    }
    &.last-line {
      right: 0;
    }
  }
`

const NavbarMenuBrand = styled(NavbarBrand)`
  position: absolute;
  width: 160px;
  top: 30px;
  left: -185px;
  cursor: pointer;
  @media (min-width: 1481px) and (max-width: 1599px) {
    width: 130px;
    top: 38px;
    left: -150px;
  }
  /* @media (min-width: 1440px) and (max-width: 1599px) {
    width: 130px;
    top: 38px;
    left: -150px;
  } */
  @media (min-width: 1340px) and (max-width: 1480px) {
    width: 120px;
    top: 30px;
    left: -90px;
  }
  @media (min-width: 1200px) and (max-width: 1339px) {
    width: 100px;
    top: 10px;
    left: -35px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 110px;
    top: 5px;
    left: -10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 110px;
    top: 5px;
    left: -10px;
  }
  @media (max-width: 767px) {
    position: static;
    width: 130px;
  }
`

const NavbarMenuToggler = styled(NavbarToggler)`
  @media (max-width: 767px) {
    position: relative;
    margin-left: auto;
    border: none;
    z-index: 200;
  }
  .navbar-toggler-icon {
    background-image: url(${Hamburger});
  }
`

const NavbarMenuNav = styled(Nav)`
  @media (min-width: 1440px) {
    position: relative;
    right: -50px;
  }
  @media (max-width: 767px) {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const NavbarMenuNavItem = styled(NavItem)`
  font-family: ${(props) => props.theme.fontMontserrat};
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
`

const NavbarMenuNavLink = styled(LinkScroll)`
  ${NavLink}
  color: ${(props) => props.theme.textColorWhite}!important;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (max-width: 767px) {
    color: ${(props) => props.theme.textColorBlack}!important;
  }
`

const NavbarMenuDropdownWrapper = styled.div`
  ${NavLink}
  padding-top: 5px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 5px;
`

const NavbarMenuNavItemDropdown = styled(NavbarMenuNavItem)`
  @media (max-width: 767px) {
    display: none;
  }
`

const NavbarMenuDropdown = styled(Dropdown)``

const NavbarMenuDropdownToggle = styled(DropdownToggle)`
  position: relative;
  min-width: 65px;
  background-color: ${(props) => props.theme.colorOrange}!important;
  color: ${(props) => props.theme.textColorBlack}!important;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  border-radius: 0;
  border: none !important;
  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.colorOrange}!important;
    color: ${(props) => props.theme.textColorBlack}!important;
    outline: none !important;
    box-shadow: none !important;
  }
  &:after {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -2px;
    margin-left: 0;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-bottom: 0;
    border-left: 4px solid transparent;
  }
`

const NavbarMenuDropdownMenu = styled(DropdownMenu)`
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  min-width: initial;
  background-color: #56575b;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0 !important;
  transform: initial !important;
  border-radius: 0;
  border: none !important;
`

const NavbarMenuDropdownItem = styled(DropdownItem)`
  text-align: left;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colorOrange};
  }
`

const NavbarMenuDropdownItemLink = styled(Link)`
  display: inline-block;
  width: 100%;
  color: ${(props) => props.theme.textColorWhite};
  font-size: 13px;
  font-weight: 600;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.textColorWhite};
  }
`

const NavbarMenuLanguagesMobile = styled(NavbarMenuNavItem)`
  @media (min-width: 768px) {
    display: none;
  }
`
const NavbarMenuLanguagesMobileList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`

const NavbarMenuLanguagesMobileItem = styled.li`
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  &.active {
    span {
      color: ${(props) => props.theme.textColorOrange};
    }
  }
`

const NavbarMenuLanguagesMobileLink = styled(Link)`
  display: inline-block;
  color: ${(props) => props.theme.textColorBlack};
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.textColorOrange};
  }
`

class NavbarMenu extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.toggleLanguage = this.toggleLanguage.bind(this)
    this.toggleScroll = this.toggleScroll.bind(this)
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      isMobile: false,
    }
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      console.log(window.innerWidth)
      if (window.innerWidth <= 767) {
        this.setState({
          isMobile: true,
        })
      } else {
        this.setState({
          isMobile: false,
        })
      }
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  toggleLanguage() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  toggleScroll() {
    this.setState({
      isOpen: false,
    })
  }

  render() {
    return (
      <div>
        <NavbarMenuWrapper color="light" light expand="md">
          <NavbarMenuContainer>
            <div className="solid-line first-line" />
            <div className="solid-line one-line" />
            <div className="solid-line two-line" />
            <div className="solid-line three-line" />
            <div className="solid-line last-line" />
            <NavbarMenuBrand as="div" className="navbar-brand">
              <SmallLogo locale={this.props.locale} />
            </NavbarMenuBrand>
            <NavbarMenuToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <NavbarMenuNav className="ml-auto" navbar>
                <NavbarMenuNavItem>
                  <NavbarMenuNavLink
                    href="/"
                    to="specialization"
                    className="nav-link"
                    onClick={() => this.toggleScroll()}
                    offset={!this.state.isMobile ? 0 : -180}
                    smooth
                  >
                    <FormattedMessage id="NAVBAR_MENU_SPECIALIZATION" />
                  </NavbarMenuNavLink>
                </NavbarMenuNavItem>
                <NavbarMenuNavItem>
                  <NavbarMenuNavLink
                    href="/"
                    to="team"
                    className="nav-link"
                    onClick={() => this.toggleScroll()}
                    offset={!this.state.isMobile ? 130 : -180}
                    smooth
                  >
                    <FormattedMessage id="NAVBAR_MENU_TEAM" />
                  </NavbarMenuNavLink>
                </NavbarMenuNavItem>
                <NavbarMenuNavItem>
                  <NavbarMenuNavLink
                    href="/"
                    to="responsibility"
                    className="nav-link"
                    onClick={() => this.toggleScroll()}
                    offset={!this.state.isMobile ? 0 : -180}
                    smooth
                  >
                    <FormattedMessage id="NAVBAR_MENU_RESPNSIBILITY" />
                  </NavbarMenuNavLink>
                </NavbarMenuNavItem>
                <NavbarMenuNavItem>
                  <NavbarMenuNavLink
                    href="/"
                    to="contacts"
                    className="nav-link"
                    onClick={() => this.toggleScroll()}
                    offset={!this.state.isMobile ? 0 : -180}
                    smooth
                  >
                    <FormattedMessage id="NAVBAR_MENU_CONTACTS" />
                  </NavbarMenuNavLink>
                </NavbarMenuNavItem>
                <NavbarMenuNavItemDropdown>
                  <NavbarMenuDropdownWrapper>
                    <NavbarMenuDropdown
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggleLanguage}
                    >
                      <NavbarMenuDropdownToggle caret>
                        {this.props.locale === 'pl' && (
                          <FormattedMessage id="LANG_PL" />
                        )}
                        {this.props.locale === 'en' && (
                          <FormattedMessage id="LANG_EN" />
                        )}
                        {this.props.locale === 'de' && (
                          <FormattedMessage id="LANG_DE" />
                        )}
                      </NavbarMenuDropdownToggle>
                      <NavbarMenuDropdownMenu>
                        {this.props.locale !== 'pl' && (
                          <NavbarMenuDropdownItem>
                            <NavbarMenuDropdownItemLink to="/">
                              <FormattedMessage id="LANG_PL" />
                            </NavbarMenuDropdownItemLink>
                          </NavbarMenuDropdownItem>
                        )}
                        {this.props.locale !== 'en' && (
                          <NavbarMenuDropdownItem>
                            <NavbarMenuDropdownItemLink to="/en">
                              <FormattedMessage id="LANG_EN" />
                            </NavbarMenuDropdownItemLink>
                          </NavbarMenuDropdownItem>
                        )}
                        {this.props.locale !== 'de' && (
                          <NavbarMenuDropdownItem>
                            <NavbarMenuDropdownItemLink to="/de">
                              <FormattedMessage id="LANG_DE" />
                            </NavbarMenuDropdownItemLink>
                          </NavbarMenuDropdownItem>
                        )}
                      </NavbarMenuDropdownMenu>
                    </NavbarMenuDropdown>
                  </NavbarMenuDropdownWrapper>
                </NavbarMenuNavItemDropdown>
                <NavbarMenuLanguagesMobile>
                  <NavbarMenuLanguagesMobileList>
                    <NavbarMenuLanguagesMobileItem
                      className={this.props.locale === 'pl' && 'active'}
                    >
                      <NavbarMenuLanguagesMobileLink
                        to="/"
                        as={this.props.locale === 'pl' && 'span'}
                      >
                        <FormattedMessage id="LANG_PL" />
                      </NavbarMenuLanguagesMobileLink>
                    </NavbarMenuLanguagesMobileItem>
                    <NavbarMenuLanguagesMobileItem
                      className={this.props.locale === 'en' && 'active'}
                    >
                      <NavbarMenuLanguagesMobileLink
                        to="/en"
                        as={this.props.locale === 'en' && 'span'}
                      >
                        <FormattedMessage id="LANG_EN" />
                      </NavbarMenuLanguagesMobileLink>
                    </NavbarMenuLanguagesMobileItem>
                    <NavbarMenuLanguagesMobileItem
                      className={this.props.locale === 'de' && 'active'}
                    >
                      <NavbarMenuLanguagesMobileLink
                        to="/de"
                        as={this.props.locale === 'de' && 'span'}
                      >
                        <FormattedMessage id="LANG_DE" />
                      </NavbarMenuLanguagesMobileLink>
                    </NavbarMenuLanguagesMobileItem>
                  </NavbarMenuLanguagesMobileList>
                </NavbarMenuLanguagesMobile>
              </NavbarMenuNav>
            </Collapse>
          </NavbarMenuContainer>
        </NavbarMenuWrapper>
      </div>
    )
  }
}

export default NavbarMenu
