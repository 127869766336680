import React, { Component } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import ScrollAnimation from 'react-animate-on-scroll'
import { Col, Row } from 'reactstrap'

import OrphanRemove from './lib/orphan-remove'

import { Title, SubTitle, TagSection, TagContainer, SectionName } from './tags'
import ResponsibilityCarousel from './responsibility-carousel'

import 'react-alice-carousel/lib/alice-carousel.css'

import ArrowLong from '../images/arrow-long.svg'
import LeftArrowIcon from '../images/left-arrow.svg'
import RightArrowIcon from '../images/right-arrow.svg'

const ResponsibilitySection = styled(TagSection)`
  overflow: hidden;
`

const ResponsibilityContainer = styled(TagContainer)`
  padding-left: 0;
  padding-right: 0;
  /* padding-bottom: 180px; */
  @media (max-width: 767px) {
    padding-bottom: 0;
  }
  ${SectionName} {
    padding-right: 30px;
  }
`

const ResponsibilityColCarousel = styled(Col)`
  display: block;
  position: relative;
  left: 0;
  padding-right: 30px;
  z-index: 150;
  @media (max-width: 767px) {
    display: none;
    padding-right: 15px;
  }
  .alice-carousel__wrapper {
    overflow: hidden;
  }
  .alice-carousel__stage {
    height: auto;
  }
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    position: absolute;
    width: initial;
    top: calc(50% - 65px);
    padding: 0;
    margin-top: -35px;
    @media (max-width: 575px) {
      top: calc(50% - 65px);
      margin-top: -36px;
    }
    .alice-carousel__prev-btn-wrapper,
    .alice-carousel__next-btn-wrapper {
      .alice-carousel__prev-btn-item,
      .alice-carousel__next-btn-item {
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
        margin-bottom: 0;
        border-radius: 3px;
        transition: ease 0.2s all;
        @media (max-width: 575px) {
          padding-left: 8px;
          padding-right: 8px;
        }
        &:hover,
        &:focus {
          opacity: 0.8;
        }
        span {
          display: block;
          &:after {
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            @media (max-width: 575px) {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }
  .alice-carousel__prev-btn {
    left: 25px;
    @media (max-width: 575px) {
      left: 20px;
    }
    .alice-carousel__prev-btn-wrapper {
      .alice-carousel__prev-btn-item {
        span {
          &:after {
            background-image: url(${LeftArrowIcon});
          }
        }
      }
    }
  }
  .alice-carousel__next-btn {
    right: 25px;
    @media (max-width: 575px) {
      right: 20px;
    }
    .alice-carousel__next-btn-wrapper {
      .alice-carousel__next-btn-item {
        span {
          &:after {
            background-image: url(${RightArrowIcon});
          }
        }
      }
    }
  }
`

const ResponsibilityColCarouselMobile = styled(ResponsibilityColCarousel)`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    min-height: 750px;
    padding-top: 45px;
  }
  @media (max-width: 575px) {
    padding-top: 35px;
  }
`

const ResponsibilityColDescWrapper = styled(Col)`
  padding-bottom: 265px;
`

const ResponsibilityColDesc = styled(Col)`
  padding-left: 0;
  @media (max-width: 767px) {
    padding-left: 30px;
    padding-right: 15px;
  }
`

const ResponsibilityTitle = styled(Title)``

const ResponsibilitySubTitle = styled(SubTitle)``

const ResponsibilitySlideDesc = styled.div`
  color: ${(props) => props.theme.textColorGrayLight};
  font-size: 15px;
  text-align: right;
  padding-left: 15px;
  margin-top: 20px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    min-height: 130px;
  }
  @media (max-width: 767px) {
    padding-right: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    min-height: 130px;
  }
  @media (max-width: 575px) {
    min-height: 130px;
  }
  a {
    color: ${(props) => props.theme.textColorBlack};
    font-weight: 700;
  }
`

const ResponsibilityDesc = styled.div`
  color: ${(props) => props.theme.textColorGrayLight};
  @media (min-width: 1600px) {
    min-height: 145px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    min-height: 113px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    min-height: 75px;
  }
`

const ResponsibilityColItems = styled(Col)`
  position: relative;
  padding-top: 20px;
  /* padding-left: 0; */
  @media (min-width: 1200px) {
    padding-top: 25px;
  }
  @media (max-width: 767px) {
    padding-left: 35px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: -10px;
    display: block;
    height: 5px;
    background-color: ${(props) => props.theme.colorOrange};
    @media (max-width: 767px) {
      content: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-color: ${(props) => props.theme.colorGrayLight};
    left: 25px;
    right: 25px;
    z-index: 150;
    @media (min-width: 768px) {
      content: none;
    }
  }
`

const ResponsibilityRowItemsWrapper = styled(Row)`
  position: relative;
  margin-right: -3px;
  z-index: 200;
  @media (max-width: 767px) {
    padding-bottom: 35px;
  }
`

const ResponsibilityColItem = styled(Col)`
  padding-left: 0;
  padding-right: 10px;
  @media (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const ResponsibilityItemImageWrapper = styled.div`
  margin-bottom: 10px;
  @media (max-width: 767px) {
    padding-top: 20px;
  }
`

const ResponsibilityItemLinkWrapper = styled.div`
  color: ${(props) => props.theme.textColorGrayLight};
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
  }
  a {
    color: ${(props) => props.theme.textColorBlack};
    font-weight: 700;
  }
`

const ResponsibilityItemDescWrapper = styled.div`
  color: ${(props) => props.theme.textColorGrayLight};
  font-size: 15px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
  }
`

const ResponsibilityButton = styled.a`
  position: relative;
  display: inline-block;
  height: 34px;
  background-color: ${(props) => props.theme.colorOrange};
  color: ${(props) => props.theme.textColorBlack};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  text-transform: uppercase;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 95px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 0;
  margin-bottom: 20px;
  @media (min-width: 768px) and (max-width: 991px) {
    width: 105%;
    font-size: 12px;
  }
  @media (max-width: 767px) {
    font-size: 15px;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.colorOrange}!important;
    color: ${(props) => props.theme.textColorBlack}!important;
    border: none;
    box-shadow: none !important;
    outline: none;
    &:after {
      right: 10px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    display: block;
    width: 50px;
    height: 8px;
    background-image: url(${ArrowLong});
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
    margin-top: -4px;
    transition: all 0.2s ease;
  }
`

class Responsibility extends Component {
  constructor(props) {
    super(props)

    this.state = {
      slides: this.props.slides.edges,
      responsive: {
        0: { items: 1 },
      },
    }
  }

  render() {
    const items = this.state.slides.map(({ node }) => {
      return (
        <div key={node.id}>
          <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
          <ResponsibilitySlideDesc
            dangerouslySetInnerHTML={{
              __html: node.frontmatter.caption,
            }}
          />
        </div>
      )
    })

    return (
      <ResponsibilitySection id="responsibility">
        <ResponsibilityContainer>
          <div className="solid-line first-line" />
          <div className="solid-line one-line" />
          <div className="solid-line two-line" />
          <div className="solid-line three-line" />
          <div className="solid-line last-line" />
          <SectionName>{this.props.data.frontmatter.section_name}</SectionName>
          <Row>
            <ResponsibilityColCarousel xs="12" md="6">
              <ResponsibilityCarousel items={items} />
            </ResponsibilityColCarousel>
            <ResponsibilityColDescWrapper xs="12" md="6">
              <Row>
                <ResponsibilityColDesc xs="12">
                  <ResponsibilityTitle>
                    {this.props.data.frontmatter.title}
                  </ResponsibilityTitle>

                  <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
                    <ResponsibilitySubTitle>
                      {this.props.locale === 'pl' ? (
                        <OrphanRemove
                          text={this.props.data.frontmatter.sub_title}
                        />
                      ) : (
                        this.props.data.frontmatter.sub_title
                      )}
                    </ResponsibilitySubTitle>
                  </ScrollAnimation>

                  <ResponsibilityDesc>
                    {this.props.locale === 'pl' ? (
                      <OrphanRemove tagName="div" text={this.props.data.html} />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.data.html,
                        }}
                      />
                    )}
                  </ResponsibilityDesc>
                </ResponsibilityColDesc>
                <ResponsibilityColCarouselMobile xs="12" md="6">
                  <ResponsibilityCarousel items={items} />
                </ResponsibilityColCarouselMobile>
              </Row>
              <Row>
                <ResponsibilityColItems xs="12">
                  <ResponsibilityRowItemsWrapper>
                    {this.state.slides.map(({ node }, idx) => {
                      return (
                        <ResponsibilityColItem md="6" key={node.id}>
                          <ResponsibilityItemImageWrapper>
                            <Img
                              fluid={
                                node.frontmatter.logo_image.childImageSharp
                                  .fluid
                              }
                            />
                          </ResponsibilityItemImageWrapper>
                          <ResponsibilityItemLinkWrapper
                            dangerouslySetInnerHTML={{
                              __html: node.frontmatter.link_desc,
                            }}
                          />
                          {this.props.locale === 'pl' ? (
                            <ResponsibilityItemDescWrapper>
                              <OrphanRemove tagName="div" text={node.html} />
                            </ResponsibilityItemDescWrapper>
                          ) : (
                            <ResponsibilityItemDescWrapper
                              dangerouslySetInnerHTML={{
                                __html: node.html,
                              }}
                            />
                          )}
                          <ScrollAnimation
                            animateIn="fadeIn"
                            duration={2}
                            animateOnce={true}
                          >
                            <ResponsibilityButton
                              href={node.frontmatter.button_link}
                              target="_blank"
                            >
                              <FormattedMessage id="BUTTON_CHECK" />
                            </ResponsibilityButton>
                          </ScrollAnimation>
                        </ResponsibilityColItem>
                      )
                    })}
                  </ResponsibilityRowItemsWrapper>
                </ResponsibilityColItems>
              </Row>
            </ResponsibilityColDescWrapper>
          </Row>
        </ResponsibilityContainer>
      </ResponsibilitySection>
    )
  }
}

export default Responsibility
