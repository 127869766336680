import React from 'react'
import styled from 'styled-components'
import { Container } from 'reactstrap'

import NavbarMenu from './navbar-menu'
import Advantages from './advantages'

import HeaderBGImage from '../images/header_bg.svg'

const HeaderWrapper = styled.header`
  overflow: hidden;
  position: relative;
`

const HeaderBGContainer = styled(Container)`
  position: absolute;
  top: 0;
  left: -30px;
  right: 0;
  bottom: 0;
`

const HeaderBGContainerWrapper = styled.div`
  width: 120%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 768px) {
    background-image: url(${HeaderBGImage});
  }
`

const Header = (props) => {
  return (
    <HeaderWrapper>
      <HeaderBGContainer>
        <HeaderBGContainerWrapper />
      </HeaderBGContainer>
      <NavbarMenu locale={props.locale} />
      <Advantages slides={props.slides} locale={props.locale} />
    </HeaderWrapper>
  )
}

export default Header
