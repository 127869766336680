import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Link as LinkScroll } from 'react-scroll'
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap'

import { TagSection, TagContainer } from './tags'

const FooterSection = styled(TagSection)`
  overflow: hidden;
`

const FooterContainer = styled(TagContainer)`
  background-color: #bfb7b0;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 767px) {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
  }
  &:after {
    background-color: #bfb7b0;
  }
`

const FooterNavLink = styled(LinkScroll)`
  ${NavLink}
  position: relative;
  color: #270d26;
  font-size: 13px;
  text-transform: uppercase;
  z-index: 150;
  &:hover,
  &:focus {
    color: #270d26;
  }
`

const LastNavItem = styled(NavItem)`
  line-height: 0px;
  margin-left: 30px;
  width: auto;

  @media (max-width: 991px) {
    display: block;
    margin-left: 15px;
    margin-top: 15px;
    width: 100%;
  }

  .gatsby-image-wrapper {
    max-width: 120px;
  }

  span {
    font-size: 12px;
  }
`

const Footer = () => {
  return (
    <FooterSection as="footer" id="footer">
      <FooterContainer>
        <div className="solid-line first-line" />
        <div className="solid-line one-line" />
        <div className="solid-line two-line" />
        <div className="solid-line three-line" />
        <div className="solid-line last-line" />
        <Row>
          <Col>
            <Nav>
              <NavItem>
                <FooterNavLink
                  href="/"
                  to="specialization"
                  className="nav-link"
                  smooth
                >
                  <FormattedMessage id="NAVBAR_MENU_SPECIALIZATION" />
                </FooterNavLink>
              </NavItem>
              <NavItem>
                <FooterNavLink href="/" to="team" className="nav-link" smooth>
                  <FormattedMessage id="NAVBAR_MENU_TEAM" />
                </FooterNavLink>
              </NavItem>
              <NavItem>
                <FooterNavLink
                  href="/"
                  to="responsibility"
                  className="nav-link"
                  smooth
                >
                  <FormattedMessage id="NAVBAR_MENU_RESPNSIBILITY" />
                </FooterNavLink>
              </NavItem>
              <NavItem>
                <FooterNavLink
                  href="/"
                  to="contacts"
                  className="nav-link"
                  smooth
                >
                  <FormattedMessage id="NAVBAR_MENU_CONTACTS" />
                </FooterNavLink>
              </NavItem>

              <StaticQuery
                query={graphql`
                  query {
                    marquesLogoImage: file(
                      relativePath: { eq: "marques-logo.png" }
                    ) {
                      childImageSharp {
                        fluid(maxWidth: 200) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                `}
                render={(data) => (
                  <LastNavItem>
                    <span>
                      <FormattedMessage id="NAVBAR_MENU_PART_OF" />
                    </span>
                    <Img
                      fluid={data.marquesLogoImage.childImageSharp.fluid}
                      alt="Marques"
                    />
                  </LastNavItem>
                )}
              />
            </Nav>
          </Col>
        </Row>
      </FooterContainer>
    </FooterSection>
  )
}

export default Footer
